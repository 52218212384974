<template>
	<div class="dashboard-container" style="min-width: 1200px">

		<!--<div style="padding-top: 5px; height: 70px; width: 100%; background-color: #f9f9f9;line-height: 48px">-->
		<!--	<div class="search-container-fn-input">-->
		<!--		<time-type-select-->
		<!--			class="time-select"-->
		<!--			:width="190"-->
		<!--			default-type="month"-->
		<!--			@change="timeTypeChange"/>-->
		<!--		&lt;!&ndash;<el-button&ndash;&gt;-->
		<!--		&lt;!&ndash;	class="sort-btn"&ndash;&gt;-->
		<!--		&lt;!&ndash;	icon="wk wk-manage"&ndash;&gt;-->
		<!--		&lt;!&ndash;	@click="setSortShow = true" />&ndash;&gt;-->
		<!--	</div>-->
		<!--</div>-->


		<!--医院简报-->
		<el-card class="report-container" style="">
			<el-row style="margin-bottom: 0">
				<el-col :span="24" style="display: flex;justify-content: space-between; align-items: center">
					<div class="grid-content bg-purple-dark" style="margin-right: 20px">
						<i class="el-icon-school"
							style="color: #15cb45;font-size: 16px;line-height: 36px;font-weight: bold"></i>
						<span style="margin-left:5px; color: #444;font-weight: bold;line-height: 36px">医院简报</span>
					</div>
					<time-type-select
						class="time-select"
						:width="190"
						default-type="month"
						@change="timeTypeChange"/>
					<!--<el-button-->
					<!--	class="sort-btn"-->
					<!--	icon="wk wk-manage"-->
					<!--	@click="setSortShow = true" />-->
				</el-col>
			</el-row>
			<el-row :gutter="30">
				<el-col class="report-item-wrapper" :span="6" style="margin-top: 15px" v-for="(item) in dataIntro"
					:key="item.key" >
					<el-row type="flex" justify="center" align="middle" class="report-item grid-content bg-purple">
						<el-col  :md="7" :lg="6" :xl="6">
							<div style="margin-left: 15px"
								:style="'width: 50px;height: 50px;border-radius: 50%; display: flex;justify-content: center; align-items: center; background-color:'+ item.bgc">
								<img style="width: 25px;height: 25px;" :src="item.img">
							</div>
						</el-col>
						<el-col  :md="13" :lg="12" :xl="12">
							<div style="margin-bottom: 4px; font-size: 15px; color: #333">{{item.title}}</div>
							<div style="margin-top: 4px; font-size: 20px;font-weight: bold; color: #333">
								{{reportHospitalData[item.key]}}</div>
						</el-col>
						<el-col :md="1" :lg="2" :xl="2">
							<div style="border-left: 2px #efefef solid; height: 30px"></div>
						</el-col>
						<el-col  :md="2" :lg="4" :xl="4">
							<div>{{item.unit}}</div>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</el-card>

		<!--问诊记录 Bar &运营数据 Table -->
		<div style="height: 486px;margin-top:10px">
			<el-row :gutter="20" class="row-bg">
				<el-col :span="12">
					<div class="grid-content bg-purple" style="background-color: #fff; padding: 15px">
						<el-row style="margin-bottom: 0">
							<el-col :span="8">
								<el-row>
									<el-col :span="22">
										<div class="grid-content bg-purple-dark">
											<i class="iconfont"
												style="width: 25px; color: #f8a73c; line-height: 36px; font-weight: bold; font-size: 16px">&#xea99;</i>
											<span style="margin-left: 5px; color: #444;font-weight: bold;line-height: 36px">问诊记录</span>
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
						<el-row style="margin-bottom: 0;">
							<el-col :span="8">
								<el-row>
									<el-col :span="2">
										<div class="grid-content bg-purple-dark">
										</div>
									</el-col>
									<el-col :span="22">
										<div class="grid-content bg-purple-dark">
										</div>
									</el-col>
								</el-row>
							</el-col>
							<el-col :span="16">
								<el-row>
									<el-col :span="12">
										<div class="grid-content bg-purple-dark">

										</div>
									</el-col>
									<el-col :span="4">
										<div class="grid-content bg-purple-dark">
											<el-button class="choice-time"
												:class="{'is-active': this.barChoiceTime==='month'}" type="text"
												size="medium" @click="bar_time('month')">近一月</el-button>
										</div>
									</el-col>
									<el-col :span="4">
										<div class="grid-content bg-purple-dark">
											<el-button class="choice-time"
												:class="{'is-active': this.barChoiceTime==='week'}" type="text"
												size="medium" @click="bar_time('week')">近一周</el-button>
										</div>
									</el-col>
									<el-col :span="4">
										<div class="grid-content bg-purple-dark">
											<!--<el-button class="choice-time"-->
											<!--	:class="{'is-active': this.barChoiceTime==='近24小时'}" type="text"-->
											<!--	size="medium" @click="bar_time('近24小时')">近24小时</el-button>-->
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>

						<el-row>
							<div class="therapy-record-container grid-content bg-purple">
								<div ref="therapyRecordBar" class="therapy-record-bar"
									style=" height:384px"></div>
							</div>
						</el-row>
					</div>
				</el-col>

				<!--table 运营数据-->
				<el-col :span="12">
					<div class="grid-content bg-purple" style="background-color: #fff; padding: 12px">
						<el-row style="margin-bottom: 0">
							<el-col :span="8">
								<el-row>
									<el-col :span="16">
										<div class="grid-content bg-purple-dark">
											<i class="iconfont"
												style="width: 25px; color: #2361f7; line-height: 36px; font-weight: bold; font-size: 16px">&#xe6e2;</i>
											<span style="margin-left:5px; color: #444;font-weight: bold;line-height: 36px">运营数据</span>
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
						<el-row style="margin-bottom: 0">
							<el-col :span="8">
								<el-row>
									<el-col :span="2">
										<div class="grid-content bg-purple-dark">
										</div>
									</el-col>
									<el-col :span="22">
										<div class="grid-content bg-purple-dark">
										</div>
									</el-col>
								</el-row>
							</el-col>
							<el-col :span="16">
								<el-row>
									<el-col :span="14">
										<div class="grid-content bg-purple-dark">

										</div>
									</el-col>
									<el-col :span="10">
										<div class="search-container-fn-input">
											<el-select v-model="choiceReportOperateDate" clearable
												placeholder="请选择" size="small" @change="handleSelectChange">
												<template slot="prefix">
													<i class="iconfont"
														style="width: 25px; line-height: 30px;color: #333">&#xe60b;</i>
												</template>
												<el-option v-for="item in choiceReportOperateDateList" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
						<el-row>
							<div class="therapy-record-container grid-content bg-purple" style=" height:389px">
								<el-table :data="tableData" border max-height="389" :summary-method="getSummaries"
									show-summary style="width: 100%">
									<el-table-column prop="" label="" align="center" width="80">
										<el-table-column prop="id" label="" align="center" width="80">
											<template slot-scope="scope">
												<p style="font-weight: 900">{{ scope.row.m }}</p>
											</template>
										</el-table-column>
									</el-table-column>
									<el-table-column prop="emr_count" align="center" label="电子病历">
									</el-table-column>
									<el-table-column prop="prescription_count" align="center" label="电子处方">
									</el-table-column>
									<el-table-column prop="pres_count" align="center" label="处方流转">
									</el-table-column>
									<el-table-column prop="doctor_count" align="center" label="医师注册">
									</el-table-column>
									<el-table-column prop="phar_count" align="center" label="药师注册">
									</el-table-column>
									<el-table-column prop="patient_count" align="center" label="患者注册">
									</el-table-column>
								</el-table>
							</div>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>

		<!--问诊来源 Pie&患者来源 Pie-->
		<div style="height: 450px; margin-top:20px;">
			<el-row :gutter="20" class="row-bg">
				<!--问诊来源 pie 图-->
				<el-col :span="12">
					<div class="grid-content bg-purple" style="background-color: #fff; padding: 15px">
						<el-row style="margin-bottom: 0">
							<el-col :span="8">
								<el-row>
									<el-col :span="24">
										<div class="grid-content bg-purple-dark">
											<i class="iconfont"
												style="width: 25px; color: #ff71dd; font-weight: bold; font-size: 16px">&#xe663;</i>
											<span style="margin-left: 5px;color: #444;font-weight: bold;line-height: 36px">问诊来源</span>
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
						<div class="therapy-source-container grid-content bg-purple">
							<div ref="therapySourcePie" class="interrogation-source-pie" style=" height:384px">
							</div>
						</div>
					</div>
				</el-col>

				<!--患者来源 pie 图-->
				<el-col :span="12">
					<div class="grid-content bg-purple-light" style="background-color: #fff; padding: 15px">
						<el-row style="margin-bottom: 0">
							<el-col :span="8">
								<el-row>
									<el-col :span="24">
										<div class="grid-content bg-purple-dark">
											<i class="iconfont"
												style="width: 25px; color: #43b7fc; font-weight: bold; font-size: 16px">&#xe62f;</i>
											<span style="margin-left: 5px;color: #444;font-weight: bold;line-height: 36px">患者来源</span>
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
						<div class="patient-source-container grid-content bg-purple">
							<div ref="patientSourcePie" class="patient-source-pie" style=" height:384px"></div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import {
		dataIntro,
		supervise_status,
		operation_status,
	} from './components/testData'
	import Sticky from './components/sticky'
  import TimeTypeSelect from '@/components/TimeTypeSelect'


	import {getReportHospital, getReportDiagnosis, getReportOperate, getReportFromSource} from "@/api/dashboard";

	export default {
		name: 'Dashboard',
		components: {
      // Sticky,
      TimeTypeSelect
		},
		async created() {
		  await this._getReportHospital(this.filterValue.timeLine)
			await this._getReportDiagnosis({type: this.barChoiceTime})
			await this._getReportOperate({year: this.choiceReportOperateDate})
			await this._getReportFromSource()
			this.supervise_status = supervise_status
			this.operation_status = operation_status
			this.dataIntro = dataIntro
			this.searchParams.supervise_status = this.supervise_status[0].options[4].value
			this.searchParams.operation_status = this.operation_status[1].value
		},
		mounted() {
			this._initChart()
			this._initChartPieTherapySource()
			this._initChartPiePatientSource()
			window.addEventListener("resize", this.chartInstance.resize);
			window.addEventListener("resize", this.chartInstancePieTherapySource.resize);
			window.addEventListener("resize", this.chartInstancePiePatientSource.resize);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.chartInstance)
			window.removeEventListener('resize', this.chartInstancePieTherapySource)
			window.removeEventListener('resize', this.chartInstancePiePatientSource)
		},
    computed: {},
		watch:{
      barDataDiagnosis: {
        handler(newValue, oldValue) {
          this.barDataDiagnosisX = []
          this.barDataDiagnosisPresY = []
          this.barDataDiagnosisEmrY = []
          if (Object.keys(newValue).length) {
            newValue.pres.forEach(item => {
              this.barDataDiagnosisX.push(item.day)
							this.barDataDiagnosisPresY.push(item.count)
            })
            newValue.emr.forEach(item => {
              this.barDataDiagnosisEmrY.push(item.count)
            })
					}
          this.$nextTick(() => {
            this.chartInstance.dispose()
            this._initChart()
					})
				},
				immediate: true,
				deep: true
			},
      fromSourceDate: {
        handler(newValue, oldValue) {
        	this.diagnosisFrom = []
          this.patientFrom = []
          if (Object.keys(newValue).length) {
						this.diagnosisFrom = newValue['diagnosis_from']
            this.patientFrom = newValue['patient_from']
          }
          this.$nextTick(() => {
            this.chartInstancePieTherapySource.dispose()
            this.chartInstancePiePatientSource.dispose()
            this._initChartPieTherapySource()
						this._initChartPiePatientSource()
          })
				},
				immediate: true,
				deep: true,
			}
		},
		data() {
			return {
        filterValue: {
          dataType: 2,
          users: [],
          strucs: [],
          timeLine: {
            type: 'month',
            value: 'default'
          }
        },
				supervise_status: [],
				operation_status: [],
				dataIntro: [],
				tableData: [],
				chartInstance: null,
				chartInstancePieTherapySource: null,
				chartInstancePiePatientSource: null,
				searchParams: {
					orderTime: []
				},
				total: 0,
				listLoading: false,
				barChoiceTime: 'month',
        reportHospitalData: {},
				barDataDiagnosis: {},
        barDataDiagnosisX: [],
        barDataDiagnosisEmrY: [],
        barDataDiagnosisPresY: [],
        choiceReportOperateDate: '2022',
				choiceReportOperateDateList: [{
            value: '2020',
            label: '2020'
          }, {
            value: '2021',
            label: '2021'
          }, {
            value: '2022',
            label: '2022'
          }],
        fromSourceDate: {},
        diagnosisFrom: [], // 问诊来源数据
        patientFrom: [], // 患者来源数据
				nowTime: this.$moment().format("YYYY年MM月DD日")
			}
		},
		methods: {
      _getReportHospital(params) {
        this.listLoading = true
        getReportHospital(params).then(response => {
          let data = response
          if (data.code === 200) {
            this.reportHospitalData = data.data
          }
          this.listLoading = false
        }).catch(error => {
          console.log(error);
          this.listLoading = false
        })
      },
			_getReportDiagnosis(params) {
        this.listLoading = true
        getReportDiagnosis(params).then(response => {
          let data = response
          if (data.code === 200) {
            this.barDataDiagnosis = data.data
          }
          this.listLoading = false
        }).catch(error => {
          console.log(error);
          this.listLoading = false
        })
      },
      _getReportOperate(params) {
        this.listLoading = true
        getReportOperate(params).then(response => {
          let data = response
          if (data.code === 200) {
            this.tableData = data.data
          }
          this.listLoading = false
        }).catch(error => {
          console.log(error);
          this.listLoading = false
        })
      },
			_getReportFromSource(params) {
        this.listLoading = true
        getReportFromSource(params).then(response => {
          let data = response
          if (data.code === 200) {
            this.fromSourceDate = data.data
          }
          this.listLoading = false
        }).catch(error => {
          console.log(error);
          this.listLoading = false
        })
			},

      _initChart() {
				const initOption = {
					color: ['#2362fb', '#fcc810'],
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: 50,
						right: 30,
						bottom: 40,
					},
					legend: {
						data: ['电子处方' , '电子病历' ],
						itemWidth: 40,
						itemHeight: 20,
					},
					xAxis: {
						type: 'category',
						// data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
						data: this.barDataDiagnosisX
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '电子处方',
							type: 'bar',
							barWidth: '20%', //柱图宽度
							data:  this.barDataDiagnosisPresY
						},
						{
							name: '电子病历',
							type: 'bar',
							barWidth: '20%', //柱图宽度
							data: this.barDataDiagnosisEmrY
						}
					]
				}
				this.chartInstance = this.$echarts.init(this.$refs.therapyRecordBar)
        this.chartInstance.showLoading()
				this.chartInstance.setOption(initOption)
        this.chartInstance.hideLoading();
			},
			_initChartPieTherapySource() {
				const initOption = {
					color: ['#2362fb', '#fcc810', '#17e24b'],
					title: {
						text: `数据时间: ${this.nowTime}`,
						textStyle: {
							color: '#666',
						},
						left: '35%',
						bottom: '0'
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c} ({d}%)'
					},
					legend: {
						itemWidth: 40,
						itemHeight: 20,
						data: ['患者场景', '医管家', '慢病场景']
					},
					series: [{
						name: '问诊来源',
						type: 'pie',
						radius: ['43%', '62%'],
						center: ['50%', '50%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: true,
							position: 'outside'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '20',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: true
						},
						data: this.diagnosisFrom
					}]
				}
				this.chartInstancePieTherapySource = this.$echarts.init(this.$refs.therapySourcePie)
				this.chartInstancePieTherapySource.setOption(initOption)
			},
			_initChartPiePatientSource() {
				const initOption = {
					color: ['#2362fb', '#fcc810', '#17e24b'],
					title: {
						text: `数据时间: ${this.nowTime}`,
						textStyle: {
							color: '#666',
						},
						left: '35%',
						bottom: '0'
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c} ({d}%)'
					},
					legend: {
						itemWidth: 40,
						itemHeight: 20,
						data: ['患者场景', '慢病场景', '医生场景']
					},
					series: [{
						name: '问诊来源',
						type: 'pie',
						radius: ['43%', '62%'],
						center: ['50%', '50%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: true,
							position: 'outside'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '20',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: true
						},
						data: this.patientFrom
					}]
				}
				this.chartInstancePiePatientSource = this.$echarts.init(this.$refs.patientSourcePie)
				this.chartInstancePiePatientSource.setOption(initOption)
			},
      timeTypeChange(data) {
        console.log(data)
        this.filterValue.timeLine = data
        let type = data.type
        let value = data.value
				if ( type === 'custom') {
          const startTime = data.startTime.split('.').join('-')
          const endTime = data.endTime.split('.').join('-')
          this.filterValue.timeLine.start_date = startTime
          this.filterValue.timeLine.end_date = endTime
					value = 'custom'
					type = ''
        }
				if (value === 'lastWeek') {
				  value = 'last_week'
				} else if (value ==='lastMonth'){
				  value = 'last_month'
				}
				this.filterValue.timeLine.value = type
				this.filterValue.timeLine.type = value
				
				this._getReportHospital(this.filterValue.timeLine)
      },

			bar_time(value) {
        console.log(value)
				this.barChoiceTime = value
				this._getReportDiagnosis({type: this.barChoiceTime})
			},
			
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '汇总';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index] += ' ';
					} else {
						sums[index] = 'N/A';
					}
				});

				return sums;
			},
			resizeTheChart() {
				if (this.$refs.lineChart) {
					this.$refs.lineChart.resize();
				}
				if (this.$refs.lineChart2) {
					this.$refs.lineChart2.resize();
				}

			},
			handleSelectChange() {
        console.log(this.choiceReportOperateDate);
        this._getReportOperate({year: this.choiceReportOperateDate})
        this.$forceUpdate()
			},
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep.el-card {
		.el-card__body {
			padding: 15px;
		}
	}
	
	::v-deep.time-select {
		.el-icon-arrow-up {
			top: 10px
		}
	}
	
	/*医院简报鼠标效果*/
	.report-item{
		height: 96px;
		box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
		&:hover{
			box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
			cursor: pointer;
		}
	}
	
	// 问诊记录, 选择时间, 按钮样式
	.choice-time {
		padding-bottom: 3px;
		color: #444;
		font-weight: bold;
		font-size: 16px;
		border-bottom: 4px solid transparent;
	}

	.is-active {
		border: 0 transparent;
		border-bottom: 4px solid #2362fb;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}


	// 栅格布局
	.el-row {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	/*.bg-purple-dark {*/
	/*  background: #99a9bf;*/
	/*}*/

	/*.bg-purple {*/
	/*  background: #d3dce6;*/
	/*}*/

	/*.bg-purple-light {*/
	/*  background: #e5e9f2;*/
	/*}*/

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9f9f9;
	}

	// table 表头斜杠
	::v-deep.el-table thead.is-group th {
		background: none;
		padding: 0px;
	}

	::v-deep.el-table thead.is-group tr:first-of-type th:first-of-type,
	::v-deep.el-table thead.is-group tr:last-of-type th:first-of-type {
		background: #ffffff !important;
	}

	::v-deep.el-table thead.is-group tr:first-of-type th:first-of-type {
		border-bottom: none;
	}

	::v-deep.el-table thead.is-group tr:first-of-type th:first-of-type div.cell {
		text-align: right;
	}

	::v-deep.el-table thead.is-group tr:last-of-type th:first-of-type div.cell {
		text-align: left;
	}

	::v-deep.el-table thead.is-group tr:first-of-type th:first-of-type:before {
		content: "";
		position: absolute;
		width: 1px;
		// height: 55px;
		height: 55px; //自行调整
		top: 0;
		left: 0;
		background-color: #333;
		opacity: 0.2;
		display: block;
		// transform: rotate(-43deg);
		transform: rotate(-68deg); //自行调整
		-webkit-transform-origin: top;
		transform-origin: top;
	}

	::v-deep.el-table thead.is-group tr:last-of-type th:first-of-type:before {
		content: "";
		position: absolute;
		width: 1px;
		// height: 60px;
		height: 55px; //自行调整
		bottom: 0;
		right: 0;
		background-color: #333;
		opacity: 0.2;
		display: block;
		// transform: rotate(-45deg);  //自行调整
		transform: rotate(-68deg);
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
	}

	// table 合计行加粗
	::v-deep.el-table .el-table__footer-wrapper .cell {
		font-weight: 600;
		color: #333;
	}
</style>
